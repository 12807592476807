const REASONS: Array<{
  title: string;
  description: string;
}> = [
  {
    title: "Recency Bias",
    description:
      "Performance reviews often emphasize recent events, overlooking long-term contributions and growth. This skews evaluations and fails to capture an employee's true value over time.",
  },
  {
    title: "Subjective Data",
    description:
      "Reviews frequently rely on subjective opinions rather than objective data, leading to inconsistent and unfair assessments across teams and individuals.",
  },
  {
    title: "Time-Consuming Process",
    description:
      "The traditional review process is incredibly time-consuming for both managers and employees, taking away valuable hours that could be spent on actual work and improvement.",
  },
  {
    title: "Lack of Consistency",
    description:
      "Annual or bi-annual reviews create long gaps between feedback sessions, making it difficult for employees to make timely improvements or course corrections in their work.",
  },
  {
    title: "Office Politics",
    description:
      "The review process can be influenced by office politics and personal relationships, overshadowing actual performance and creating an unfair playing field.",
  },
  {
    title: "Limited Actionability",
    description:
      "Many performance reviews end with vague feedback or goals, leaving employees unsure of how to practically improve or advance in their roles, reducing the overall effectiveness of the process.",
  },
  {
    title: "Disconnected from Work Systems",
    description:
      "Performance review systems are often separate from the tools and platforms where actual work happens, leading to a disconnect between daily contributions and performance evaluations.",
  },
  {
    title: "Outdated Manual Process",
    description:
      "Traditional reviews rely on manual data entry and subjective recall, ignoring the wealth of digital data available about how employees work, collaborate, and perform in real-time.",
  },
];

const Why = () => {
  return (
    <div className="mx-auto max-w-screen-lg px-4 md:px-8 py-12 sm:py-18 lg:py-24">
      <h2 className="text-center text-2xl lg:text-2xl">The Broken State of Performance Reviews</h2>
      <p className="text-center text-lg text-secondary mt-2">
        Traditional performance reviews are outdated, ineffective, and universally despised.
        It&apos;s time for a change.
      </p>
      <div className="grid md:grid-cols-2 gap-4 md:gap-8 mt-8 lg:mt-16">
        {REASONS.map((reason, index) => (
          <div
            className="px-8 py-6 bg-black/5 rounded-md hover:shadow-lg transition-all hover:scale-105 duration-500"
            key={index}
          >
            <h3 className="font-medium text-xl text-balance mt-2 leading-snug">{reason.title}</h3>
            <p className="text-secondary leading-snug mt-3">{reason.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Why;
