import { useLoaderData } from "@remix-run/react";

import { Link } from "@wind/ui";

import RotatingWindmill from "~/components/RotatingWindmill/index.js";
import { useOptionalAuthUser } from "~/hooks/useAuthUser.js";
import { ArrowRightIcon, ExternalLinkIcon } from "~/utils/icons.js";
import type { loader } from "./route.js";

const REQUEST_ACCESS_TYPEFORM_URL = "https://5yjl2ozimpb.typeform.com/to/lYTfvNHM";

const Hero = () => {
  const {
    homepage: { backgroundImageUrl },
  } = useLoaderData<typeof loader>();
  const user = useOptionalAuthUser();

  return (
    <div className="relative isolate px-6 pt-14 lg:px-8 ">
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat -z-10 bg-black"
        style={{
          backgroundImage: `url('${backgroundImageUrl}')`,
        }}
      />
      <div className="absolute inset-0 bg-black/40 -z-10" />
      <div className="mx-auto max-w-4xl py-32 sm:py-48 lg:py-48">
        <div className="-mt-12 flex flex-col gap-2">
          <div className="flex items-center">
            <RotatingWindmill variant="light" />
          </div>
          <h1 className="mt-4 text-4xl tracking-tight sm:text-7xl text-white">
            Performance, Reimagined
          </h1>
          <h2 className="mt-6 text-2xl font-base sm:text-3xl tracking-tight text-balance text-white">
            Windmill is the first performance management system that does the work for you. Stop
            wasting your team&apos;s time filling out forms and focus on what matters - running your
            team.
          </h2>
          <div className="mt-8 inline-flex items-start flex-col md:flex-row gap-4">
            <a
              href={REQUEST_ACCESS_TYPEFORM_URL}
              className="text-white font-medium text-xl border-2 border-white px-4 py-2 bg-black/40 inline-flex items-center gap-2 hover:bg-white hover:text-black transition-all"
            >
              Request early access
              <ExternalLinkIcon />
            </a>
            {user && (
              <Link
                to="/s"
                className="text-white font-medium text-xl border-2 border-white px-4 py-2 bg-black/40 inline-flex items-center gap-2 hover:bg-white hover:text-black transition-all"
              >
                Open Windmill
                <ArrowRightIcon />
              </Link>
            )}
            {!user && (
              <Link
                to="/auth"
                className="text-white font-medium text-xl border-2 border-white px-4 py-2 bg-black/40 inline-flex items-center gap-2 hover:bg-white hover:text-black transition-all"
              >
                Login
                <ArrowRightIcon />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
