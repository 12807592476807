import { useLoaderData } from "@remix-run/react";
import type { LoaderFunctionArgs } from "@remix-run/server-runtime";

import Nav from "~/marketing/components/Nav.js";
import Footer from "../../marketing/components/Footer.js";
import { loadHomepageContent } from "../../ops.server/public/homepage/index.js";
import Hero from "./Hero.js";
import Why from "./Why.js";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  return loadHomepageContent.query(request, undefined);
};

const Homepage = () => {
  const { pages } = useLoaderData<typeof loader>();

  const links = pages.map((page) => ({
    to: `/p/${page.fields.slug}`,
    title: page.fields.title,
    external: false,
  }));

  return (
    <div>
      <Nav variant="light" />
      <Hero />
      <Why />
      <Footer links={links} />
    </div>
  );
};

export default Homepage;
